/* global require */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'bootstrap';

import { Application } from 'stimulus';
import flatpickr from 'flatpickr';
import Flatpickr from 'stimulus-flatpickr';
import ReactRailsUJS from 'react_ujs';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// flatpickr
const { fr } = require('flatpickr/dist/l10n/fr.js').default;

flatpickr.localize(fr);

// Allow flatpickr inside bootstrap modal
$(document).on('focusin', (e) => {
  if ($(e.target).closest('.flatpickr-calendar').length) {
    e.stopImmediatePropagation();
  }
});

// jquery
$(document).on('ready turbolinks:load', () => {
  $.ajaxSetup({
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    xhrFields: { withCredentials: true },
  });
});

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

application.register('flatpickr', Flatpickr);

function importAll(r) { r.keys().forEach(r); }
importAll(require.context('../app/', true, /\.js$/));

// React Rails
const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);
